import LandingPage from './views';
// import { NotificationContainer } from 'react-notifications';

import '../src/assets/styles/scrollbar.css';
// import 'react-notifications/lib/notifications.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <header className="App-header">
      <ToastContainer />
      <LandingPage />
    </header>
  );
}
