import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Snackbar, Fade, Button } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';


import AppAppBar from '../layouts/AppAppBar';
import Footer from '../layouts/Footer';

import AboutPlatform from '../components/AboutPlatform';
import ContactUS from '../components/ContactUS';
import FAQ from '../components/FAQ';

import defaultTheme from '../themes';

import background from '../assets/images/components/Background_wallpaper.png'
const LandingPage = () => {
  const [mode, setMode] = React.useState('light');
  const LPtheme = createTheme(defaultTheme(mode));
  const [privacyOpen, setPrivacyOpen] = React.useState(true);

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setPrivacyOpen(false);
  };

  const action = (
    <>
      <Button style={{ backgroundColor: '#A5E13B', fontWeight: 600}} variant="outlined" size="small" onClick={handleClose}>
        Prosseguir
      </Button>
    </>
  );
  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} success />
      <Snackbar
        open={privacyOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={10000}
        TransitionComponent={Fade}
        onClose={handleClose}
        message={`Sabemos que a sua privacidade é importante e por isso, não guardamos e ou rastreamos as suas informações.`}
        action={action}
        ContentProps={{
          sx: {
            color: 'black',
            borderRadius: '10px',
            fontWeight: '500'
          }
        }}
      />
      <Box sx={{ backgroundImage: `url(${background})` }}>
        <AboutPlatform />
        <FAQ />
        <ContactUS />
        <Footer mode={mode} />
      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;
